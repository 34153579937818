import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  intro,
  category,
  title,
  bgImage,
  authorimage,
  authorname,
  authorbio,
  date
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="blog-post">
      <div className="blog-post-wrapper">
        <div style={{ backgroundImage: `url(${bgImage})`}} className="promo">
          <p className="tags">
            <Link
              to={`/category/${kebabCase(category)}/`}
            >
              {category}
            </Link>
            <span className="separator"> / </span>
            <span>
              {(date).split("/").join(".")}
            </span>
          </p>
          <span className="underline"></span>
          <h1 className="heading">
            {title}
          </h1>
        </div>
        <div className="author" style={{ marginTop: `-77px` }}>
          {authorimage ? (
            <div>
              <div className="photo">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: authorimage,
                    alt: `featured image thumbnail for post ${authorname}`,
                  }}
                />
              </div>
              <h4 className="name">{authorname}</h4>
              <h5 className="position">{authorbio}</h5>
            </div>
          ) : null}
        </div>
        <div className="blog-content">
          <div className="intro">
            <p>{intro}</p>
          </div>
          <div className="post-content">
            <PostContent content={content} />
            {category ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Category</h4>
                <ul className="categorylist">
                  <li key={category + `category`}>
                    <Link to={`/category/${kebabCase(category)}/`}>{category}</Link>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}


BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  intro: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  authorimage: PropTypes.object,
  bgImage: PropTypes.string,
  authorname: PropTypes.string,
  authorbio: PropTypes.string,
  date: PropTypes.string,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        intro={post.frontmatter.intro}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="intro"
              content={`${post.frontmatter.intro}`}
            />
          </Helmet>
        }
        category={post.frontmatter.category}
        title={post.frontmatter.title}
        bgImage={post.frontmatter.featuredimage.publicURL}
        authorbio={post.frontmatter.authorbio}
        authorname={post.frontmatter.authorname}
        authorimage={post.frontmatter.authorimage}
        date={post.frontmatter.date}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        featuredimage {
          publicURL
        }
        date
        title
        intro
        category,
        authorbio
        authorname,
        authorimage {
          childImageSharp {
            fluid(maxWidth: 151, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
      }
    }
  }
`